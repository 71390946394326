@font-face {
    font-family: 'Tw Cen MT';
    src: url(../fonts/Tw-Cen-MT.ttf);
}

@font-face {
    font-family: 'General Sans';
    src: url(../fonts/GeneralSans-Medium.ttf);
}

@font-face {
    font-family: 'HvDTrial Match';
    src: url(../fonts/HvDTrial_Match-Regular.otf);
}

html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    background: #040404;
    background-color: #040404 !important;
}

::-webkit-scrollbar {
    width: 7px;
}

::-webkit-scrollbar-thumb {
    background: #22090a;
    border-radius: 100px;
}

::-webkit-scrollbar-track {
    background: #000;
}

body {
    font-family: General Sans;
}

#root {
    overflow-x: hidden;
}

.btm {
    margin-bottom: 30px;
}

.btm-129 {
    margin-bottom: 129px;
}

.section svg:not(.exchange-bg) {
    position: absolute;
    z-index: 0;
    top: 370px;
    left: 50%;
    transform: translate(-50%, -50%);
}

.section {
    position: relative;
    overflow: hidden;
    padding: 60px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.section--pb0 {
    padding-bottom: 0;
}

.section--first {
    padding-top: 70px;
}

.general-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1;
    background: url('../images/firered1.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 65%;
    height: 700px;
}

.general-info img {
    max-width: 375px;
    width: 100%;
}

.copy-contract {
    display: inline-flex;
    align-items: center;
    padding-top: 180px;
    z-index: 99;
}

.copy-contract img {
    cursor: pointer;
}

.copy-contract span {
    color: #FFF;
    font-family: HvDTrial Match;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.4px;
    border-radius: 24px;
    border: 1.5px dashed rgba(255, 255, 255, .5);
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    padding: 2px 5px 0 5px;
}

.general-info .title {
    padding-top: 22px;
    padding-bottom: 22px;
}

.general-info .title .main {
    position: relative;
    font-family: HvDTrial Match;
    color: #FFF;
    text-align: center;
    z-index: 99;
    font-weight: 900;
    font-size: 65px;
    line-height: normal;
}

.general-info .title .subt {
    display: block;
    color: #EDEDED;
    text-align: center;
    font-family: General Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%;
    letter-spacing: 0.08px;
    z-index: 99;
    position: relative;
}

.general-info img.nft-left {
    position: absolute;
    top: 165px;
    left: -235px;
    z-index: 9;
}

.general-info img.nft-right {
    position: absolute;
    top: 165px;
    right: -240px;
    z-index: 9;
}

.separator {
    position: relative;
    top: -40px;
}

.separator div.first {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 85px;
    transform: rotate(4.167deg);
    background: #22090A;
    width: 100vw;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    overflow: hidden;
    white-space: nowrap;
    z-index: 10;
}

.ticker-text {
    color: #FFF;
    text-align: center;
    font-family: 'HvDTrial Match';
    font-size: 21px;
    font-weight: 900;
    letter-spacing: 0.36px;
    text-transform: uppercase;
    margin-right: 20px; /* Spacing between text and fire image */
}

.separator div.first .icon-text {
    display: flex;
    justify-content: center;
    align-items: center;
}

.separator div.second {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 85px;
    transform: rotate(-1.942deg);
    background: #E5383B;
    width: 100vw;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    overflow: hidden;
    white-space: nowrap;
    top: -85px;
}

.live_data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

.separator_s {
    width: 100%;
    height: 160px;
    margin-top: -80px;
    margin-bottom: 20px;
}

.live_data .about {
    width: 100%;
}

.live_data .card {
    background: #310E0F;
    padding: 42px 78px 41px 77px;
    border-radius: 8px;
    border: 3px solid #8B2325;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 40px;
    width: 100%;
    z-index: 1;
}

.live_data .card .row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
}

.live_data .card .row:last-child {
    margin-bottom: 0;
}

.live_data .card .data {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.live_data .card-text {
    font-family: 'HvDTrial Match';
    font-size: 20px;
    color: #FFF;
    letter-spacing: 0.5px;
    margin-bottom: 0.5em;
}

.live_data .card-number {
    font-family: 'HvDTrial Match';
    font-size: 35px;
    color: #E5383B;
    letter-spacing: 0.2px;
    margin-bottom: 0;
}

.live_data .about h1 {
    font-family: 'HvDTrial Match';
    font-size: 35px;
    font-weight: 700;
    letter-spacing: 1px;
    color: #FFF;
    margin-bottom: 15px;
}

.live_data .about p {
    font-family: 'General Sans', sans-serif;
    color: #EDEDED;
    font-size: 16px;
    line-height: 23.2px;
    letter-spacing: 0.08px;
}

.shadow-red {
    position: absolute;
    right: 0;
    z-index: 0;
}

.staking-section {
    display: flex;
    flex-direction: row;
}

.staking-section .card {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: transparent;
}

.staking-section h2 {
    color: #FFF;
    font-family: HvDTrial Match, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.96px;
}

.staking-section p {
    color: #EEE;
    font-family: HvDTrial Match, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
}

.red-button {
    display: flex;
    width: 188px;
    padding: 10px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: #E5383B;
    border: none;
    color: #FFF;
    cursor: pointer;
    font-size: 16px;
    text-decoration: none;
}

.arrow-container {
    width: 100%;
    height: 124px;
    position: relative;
}

.arrow-container.f svg {
    position: relative;
    top: -33px;
    right: -105px;
    left: unset;
    transform: none;
    width: 160px;
}

.arrow-container.s svg {
    position: relative;
    top: -33px;
    left: -75px;
    right: unset;
    transform: none;
    width: 160px;
}

.main-image-s {
    margin-right: 85px;
}

.section-title {
    padding-top: 150px;
    padding-bottom: 40px;
    width: 100%;
    display: block;
    text-align: center;
}

.section-title .sub-title {
    color: #E5383B;
    text-align: center;
    font-family: HvDTrial Match, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.96px;
    text-transform: uppercase;
}

.section-title .title {
    color: #FFF;
    text-align: center;
    font-family: HvDTrial Match, sans-serif;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1px;
}

.nft-collection {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: url('../images/nft-collection.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 866px;
    margin-top: 145px;
}

.nft-collection .title {
    color: #FFF;
    text-align: center;
    font-family: HvDTrial Match, sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 950;
    line-height: normal;
    letter-spacing: -0.6px;
    max-width: 660px;
}

.nft-collection .description {
    color: #CCC;
    text-align: center;
    font-family: General Sans, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 145%; /* 23.2px */
    letter-spacing: 0.08px;
    max-width: 660px;
}

.nft-collection .action-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 32px;
    width: 414px;
}

.nft-collection .action-buttons .red-button {
    width: 195px;
    height: 43px;
}

.no-bg-button {
    display: flex;
    width: 195px;
    height: 43px;
    padding: 10px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1.5px solid #FEFEFE;
    color: #FEFEFE;
    text-align: center;
    font-family: General Sans, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 145%; /* 23.2px */
    letter-spacing: 0.08px;
    text-decoration: none;
}

.exchanges {
    padding-top: 25px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 25px;
}

.exchanges .card {
    position: relative;
    width: 275px;
    height: 164px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: transparent;
}

.exchange-bg {
    position: absolute;
    z-index: 0;
}

.exchanges span {
    color: #FFF;
    font-family: HvDTrial Match, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.42px;
    text-transform: uppercase;
    z-index: 1;
}

.exchanges .logo {
    width: 109px;
    margin-bottom: 10px;
    z-index: 1;
}

.exchanges .link-square {
    position: absolute;
    bottom: 1px;
    right: -3px;
    width: 35px;
    height: 35px;
    background-color: #22090A;
    border-radius: 9px;
    border: 1.5px solid #BA181B;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

.exchanges .link-square .arrow {
    color: #BA181B;
    font-weight: bold;
    font-size: 24px;
    transform: rotate(45deg);
}

.title-icon {
    margin-left: -20px;
}

.timeline {
    /*display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;*/
    position: relative;
    padding: 30px 0;
}

.phase {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 760px;
    position: relative;
    top: -111px;
}

.phase:not(:last-child) {
    margin-right: -225px;
}

.milestone-character {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
}

.milestones {
    text-align: left;
}

.milestones h2 {
    margin-bottom: 15px;
    color: #FFF;
    font-family: HvDTrial Match, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.8px;
}

.milestones ul {
    list-style: none;
    padding: 0;
}

.milestones li {
    color: #FFF;
    font-family: HvDTrial Match, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.4px;
    padding-bottom: 10px;
}

.checkmark-circle {
    width: 24px;
    height: 24px;
    margin-right: 5px;
    margin-top: -2px;
}

.checkmark-circle.empty {
    opacity: .15;
}

.step {
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translate(-50%, -50%);
    background-color: rgba(229, 56, 59, 0.2);
    width: 76px;
    height: 76px;
    border-radius: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.phase.second {
    top: -95px;
    flex-direction: row-reverse;
    right: -460px;
}

.phase.second .milestone-character, .phase.four .milestone-character {
    flex-direction: row-reverse;
}

.phase.second .step, .phase.four .step {
    left: 193px;
}

.phase.three {
    top: -65px;
}

.phase.three img:not(.checkmark-circle) {
    position: relative;
    left: -50px;
}

.phase.four {
    top: -15px;
    flex-direction: row-reverse;
    right: -460px;
}

.timeline svg {
    top: 625px !important;
}

.step .first-inner {
    width: 51px;
    height: 51px;
    border-radius: 12px;
    background: rgba(229, 56, 59, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.step .second-inner {
    width: 34px;
    height: 34px;
    border-radius: 8px;
    background: #E5383B;
    color: #FFF;
    font-family: HvDTrial Match, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.45px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Central line */
.timeline::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 12px;
    background-color: #E5383B;
}

.merch {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.merch img {
    max-width: 375px;
    width: 100%;
}

.merch .red-button {
    width: 240px;
    margin-top: 20px;
}

.tokenomics {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    gap: 15px 15px;
}

.supply, .tax, .burned, .details {
    border-radius: 8px;
    border: 1.54px solid #E5383B;
    background: #22090A;
    padding: 27px 40px;
    position: relative;
    overflow: hidden;
}

.supply p, .tax p, .burned p {
    position: relative;
    margin-bottom: 6px;
    z-index: 1;
}

.supply .card-text, .tax .card-text, .burned .card-text {
    color: #FFF;
    font-family: HvDTrial Match, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.supply .card-number, .tax .card-number, .burned .card-number {
    color: #E5383B;
    font-family: HvDTrial Match, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.4px;
}

.tax .card-number, .burned .card-number {
    font-size: 32px;
}

.tax .zero-fee {
    position: absolute;
    bottom: 10px;
    right: 20px;
    color: #FFF;
    text-align: right;
    font-family: HvDTrial Match, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.35px;
    z-index: 1;
}

.tk-3 {
    position: absolute;
    top: 18px;
    right: 0;
    z-index: 0;
}

.tk-2 {
    position: absolute;
    bottom: -5px;
    right: 0;
    z-index: 0;
}

.tk-1 {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 0;
}

.full-width-row {
    grid-column: 1 / -1;
}

.tokenomics .details {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    max-height: 175px;
}

.tokenomics .details .card-text {
    color: #CCC;
    font-family: HvDTrial Match, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.4px;
}

.tokenomics .details span {
    color: #FFF;
    font-family: HvDTrial Match, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.5px;
    padding-bottom: 6px;
    display: block;
}

.tk-last {
    position: relative;
    bottom: -30px;
}

.partners {
    display: flex;
    align-items: center;
    gap: 80px;
    justify-content: center;
    flex-wrap: wrap;
}

.footer-title {
    color: #FFF;
    text-align: center;
    font-family: HvDTrial Match, sans-serif;
    font-size: 75px;
    font-style: normal;
    font-weight: 900;
    line-height: 110%;
    letter-spacing: -1.92px;
    text-transform: uppercase;
}

.footer-title .text-container {
    color: #E5383B;
    font-family: HvDTrial Match, sans-serif;
    font-size: 75px;
    font-style: normal;
    font-weight: 900;
    line-height: 110%;
    letter-spacing: -1.92px;
    text-transform: uppercase;
    position: relative;
    display: inline-block;
}

.gradient-line {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 4px;
    background-image: linear-gradient(90deg, transparent 30px, #E5383B 30px, #E5383B 60px, transparent 60px),
    linear-gradient(to right, transparent, #E5383B, transparent);
    background-size: 20px 100%, 100% 100%;
}

.socials {
    margin-top: 40px;
    display: flex;
    align-items: center;
    gap: 32px;
    justify-content: center;
    flex-wrap: wrap;
}

.links {
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
}

.links a {
    color: #FFF;
    font-family: General Sans, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%; /* 23.2px */
    letter-spacing: 0.08px;
    text-decoration-line: underline;
}

.footer {
    background: #0C0303;
    width: 100vw;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    position: relative;
    padding: 60px 0;
    margin-top: 195px;
}

.divider {
    position: relative;
    background: #CCC;
    width: 100vw;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    height: 2px;
}

.footer-text {
    color: #CCC;
    font-family: General Sans, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%;
    letter-spacing: 0.08px;
    padding: 32px 0 0 0;
    text-align: center;
}

@media (max-width: 1399.99px) {
    .phase {
        width: 670px;
    }

    .phase.second .step, .phase.four .step {
        left: 105px;
    }
}

@media (max-width: 1199.99px) {
    .phase {
        width: 580px;
    }

    .phase.second .step, .phase.four .step {
        left: 14px;
    }

    .phase .milestone-character img:not(.checkmark-circle) {
        width: 310px;
        position: relative;
        left: -38px;
    }

    .phase.three .milestone-character img:not(.checkmark-circle) {
        left: -95px;
    }
}

@media (max-width: 992px) {
    .timeline::before {
        display: none;
    }

    .step {
        display: none;
    }

    .phase:not(:last-child) {
        margin-right: 0;
    }

    .milestones {
        z-index: 1;
    }

    .phase {
        width: 100%;
        top: 0 !important;
        margin-bottom: 20px;
        flex-direction: unset;
    }

    .phase.second, .phase.four {
        right: 0;
        flex-direction: unset;
    }

    .phase.second .milestone-character, .phase.four .milestone-character {
        flex-direction: row;
    }

    .phase .milestone-character img:not(.checkmark-circle) {
        width: 262px;
        position: relative;
        left: -22px;
        z-index: 0;
    }

    .phase.three .milestone-character img:not(.checkmark-circle),
    .phase.four .milestone-character img:not(.checkmark-circle) {
        left: -75px;
    }

    .phase.second .milestone-character img:not(.checkmark-circle) {
        left: -120px;
    }

    .timeline svg {
        width: auto !important;
    }
}

@media (max-width: 1024px) {
    .supply {
        grid-column: 1 / -1;
    }

    .tokenomics {
        grid-template-columns: 2fr 2fr;
    }

    .tk-3 {
        top: -40px;
    }

    .tokenomics .details {
        flex-direction: column;
        max-height: 275px;
    }

    .tk-last {
        bottom: 55px;
        right: -55px;
    }

    .section svg:not(.exchange-bg) {
        width: 105%;
    }

    .general-info {
        background-size: 100%;
    }

    .general-info .title .main {
        font-size: 45px;
    }

    .live_data {
        flex-direction: column-reverse;
    }

    .live_data .card {
        flex-direction: column;
        padding: 20px;
        margin-left: 0;
        margin-bottom: 45px;
    }

    .live_data .data {
        margin-bottom: 1em;
    }

    .staking-section {
        flex-direction: column;
    }

    .staking-section .card {
        justify-content: center;
    }
}

@media (max-width: 768px) {
    .general-info {
        height: 575px;
    }

    .general-info img.nft-left {
        position: relative;
        width: 190px;
        top: 0;
        left: -90px;
        margin-top: 50px;
    }

    .general-info img.nft-right {
        top: 50px;
        width: 190px;
        right: -30px;
    }

    .copy-contract {
        padding-top: 0;
    }

    .copy-contract img {
        width: 20px;
    }

    .separator div.first, .separator div.second {
        height: 51px;
    }

    .separator div.second {
        top: -55px;
    }

    .ticker-text {
        font-size: 14px;
        margin-right: 10px;
    }

    .ticker-text img {
        width: 30px;
        height: auto;
    }

    .separator_s {
        margin-top: -125px;
    }

    .live_data .card-number {
        font-size: 21px;
    }

    .live_data .card-text {
        font-size: 16px;
    }

    .live_data .about h1 {
        font-size: 32px;
    }

    .shadow-red {
        display: none;
    }

    .nft-collection {
        margin-top: 115px;
        height: 445px;
    }

    .nft-collection .title {
        font-size: 32px;
    }

    .nft-collection .action-buttons {
        flex-direction: column;
        width: 100%;
    }

    .nft-collection .action-buttons .red-button {
        width: 100%;
        margin-bottom: 15px;
    }

    .nft-collection .action-buttons .no-bg-button {
        width: 100%;
    }
    .footer-title, .footer-title .text-container {
        font-size: 40px;
    }
    .gradient-line {
        bottom: 3px;
    }
    .socials img {
        width: 40px;
        height: 40px;
    }
}

@media (max-width: 575px) {
    .timeline {
        padding: 0;
    }

    .general-info {
        background-size: 160%;
    }

    .general-info .title .main {
        font-size: 35px;
    }

    .copy-contract span {
        font-size: 12px;
    }

    .clip-copy {
        left: 190% !important;
        padding: 1px 10px !important;
    }

    .arrow-container {
        display: none;
    }

    .section-title {
        padding-top: 90px;
    }

    .staking-section .card {
        flex-direction: column;
    }

    .staking-section .card:first-child {
        flex-direction: column-reverse;
        margin-bottom: 50px;
    }

    .staking-section .card .main-info {
        text-align: center;
        width: 100%;
    }

    .staking-section .card .main-info a {
        display: block;
        width: 100%;
    }

    .main-image-s {
        margin-right: 0;
    }

    .phase .milestone-character img:not(.checkmark-circle) {
        position: absolute;
        left: 105px !important;
    }

    .phase:first-child .milestone-character img:not(.checkmark-circle) {
        left: 145px !important;
    }

    .merch .red-button {
        width: 100%;
    }

    .tk-3 {
        top: 65px;
        width: 70%;
    }

    .supply, .tax, .burned, .details {
        padding: 25px 10px;
    }
}

@media (min-width: 992px) {
    .general-info img.nft-left {
        left: -105px;
    }

    .general-info img.nft-right {
        right: -125px;
    }

    .pt-none {
        padding-top: 0;
    }
}